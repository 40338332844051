/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import MenuItem from "@material-ui/core/MenuItem";
import { useNavigate } from "../../hooks/navigation";
import { CONSULT_STATUS } from "../../redux/reducers";
import { isBefore } from "../../utils/dateUtils";
import Link from "../link";
import Select from "../select";
import ConsultCard from "./consult-card";
import SearchBoxFilter from "../search-box-filter";
// theme
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.black}`,
  },
  contractInput: {
    border: "none",
    height: "36.5px",
    paddingTop: 0,
    transform: "translateY(1px)",
    "& div > h6": {
      transform: "translateY(-2.5px)",
    },
  },
  input: {
    width: "246px",
  },
  boxClasses: {
    marginLeft: theme.spacing(0),
  },
}));

const SideMenu = ({
  consults = [],
  bookingId = 0,
  status = "initial",
  initialSearchValue = "",
  initialStateValue = "",
  hasMoreConsults,
  onChangeBookingStatus = () => {},
  onLoadMoreConsults = () => {},
  onSearchBookings = () => {},
}) => {
  const classes = useStyles({
    ...theme,
  });
  const { i18n } = useLingui();
  const { navigate } = useNavigate();
  const [listHeight, setListHeight] = useState();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [selectedBookingState, setSelectedBookingState] = useState(initialStateValue);

  const bookingStatus = {
    inprogress: i18n._(t`Consultas`),
    "to-be-started": i18n._(t`Reservas por comenzar`),
    active: i18n._(t`Reservas activas`),
    done: i18n._(t`Reservas finalizadas`),
    cancelled: i18n._(t`Canceladas`),
  };

  const handleOnClickCard = (id) => {
    localStorage.setItem("TenantLastBookingSeen", id ? `${selectedBookingState}/chat/${id}` : "");
  };

  const handleOnChangeBookingState = ({ target }) => {
    localStorage.setItem("TenantLastBookingState", target.value);
    setIsFirstRender(true);
    setSelectedBookingState(target.value);
    onChangeBookingStatus(target.value);
    handleOnClickCard(null);
    navigate(`/tenant/${target.value}/chat/`);
  };

  const resizeChat = () => {
    const box = document.getElementById("side-menu-list");
    if (box) {
      const height = window.innerHeight - box.offsetTop - 80;
      setListHeight(height);
      if (box.scrollTop === 0 || box.scrollTop - box.scrollHeight < 50) {
        box.scrollTop = box.scrollHeight;
      }
    }
  };

  const handleLoadMoreConsults = () => {
    onLoadMoreConsults();
  };

  const handleSearchBookings = (value) => {
    setIsFirstRender(true);
    onSearchBookings(value);
    handleOnClickCard(null);
    navigate(`/tenant/${selectedBookingState}/chat/`);
  };

  useEffect(() => {
    if (selectedBookingState && status === "success" && isFirstRender && !bookingId) {
      setIsFirstRender(false);
      if (consults.length) {
        handleOnClickCard(consults[0].id);
        navigate(`/tenant/${selectedBookingState}/chat/${consults[0].id}`);
      }
    }
  }, [selectedBookingState, consults, isFirstRender]);

  useEffect(() => {
    onChangeBookingStatus(initialStateValue);
    window.addEventListener("resize", resizeChat);
    resizeChat();
    return () => {
      window.removeEventListener("resize", resizeChat);
    };
  }, []);

  return (
    <Box id="side-menu-list" height="100%" width="100%">
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
        <Box width="100%">
          <Select
            id="select-contract-box"
            label=""
            className={classes.contractInput}
            value={selectedBookingState}
            onChange={handleOnChangeBookingState}
          >
            {Object.keys(bookingStatus).map((c) => (
              <MenuItem key={c} value={c}>
                <Typography variant="subtitle1">{bookingStatus[c]}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box className={classes.divider} />
      <Box mt={1} position="relative" display="flex" flexDirection="row" width="100%">
        <SearchBoxFilter
          placeholder={i18n._(t`Buscar`)}
          initialSearch={initialSearchValue}
          onSearch={handleSearchBookings}
          inputClasses={classes.input}
          boxClasses={`${classes.boxClasses}`}
        />
      </Box>
      <Box mt={1} height={listHeight} style={{ overflowY: "auto" }}>
        {status !== "loading" ? (
          consults.length ? (
            consults.map((c, index) => (
              <Link
                onClick={() => handleOnClickCard(c.id)}
                key={`${c.bookingId}-${index}`}
                to={`/tenant/${selectedBookingState}/chat/${c.id}`}
              >
                <ConsultCard
                  isSelected={bookingId === c.id}
                  bookingId={c.id}
                  title={c.space ? c.space.title : c.project_name}
                  price={c.price}
                  paymentPlan={c.payment_plan}
                  showPrices={c.show_prices_info}
                  periodPrice={c.periodPrice}
                  country={c.asset?.city?.country?.name}
                  asset={c.asset?.name}
                  start={c.start}
                  end={c.end}
                  bookingState={c.status}
                  image={c.spaces[0]?.attachments[0]?.url ? c.spaces[0]?.attachments[0]?.url : c.asset?.thumb_url}
                  parentId={c.parent_id}
                  hasNotification={c.user_last_seen ? isBefore(c.user_last_seen, c.updated_at) : true}
                  confirmedAt={c.confirmed_at}
                />
              </Link>
            ))
          ) : (
            <Box mt={3} mb={3} display="flex" justifyContent="center">
              <Typography variant="h6">
                <Trans>No hay consultas para mostrar</Trans>
              </Typography>
            </Box>
          )
        ) : (
          <Box mt={2} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        )}

        {hasMoreConsults && status !== "loading" && (
          <Box mt={2} display="flex" alignItems="center" justifyContent="center">
            <Button onClick={handleLoadMoreConsults} variant="contained" color="primary">
              <Trans>Cargar más</Trans>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SideMenu;
