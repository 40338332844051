import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import { useNavigate } from "/src/hooks/navigation";
import PayConsultation from "../../components/pay-consultation";
import {
  getBookingThunk,
  getBookingInvoicesThunk,
  payInvoiceThunk,
  payInvoiceInit,
} from "../../redux/actions/consultation-actions";
import { getConsultationStatusFromStatus } from "../../redux/reducers";

const PayConsultationContainer = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { navigate } = useNavigate();

  const invoices = useSelector((state) => state.consultation.invoices);

  useEffect(() => {
    dispatch(getBookingThunk({ bookingId: params.booking }));
    dispatch(getBookingInvoicesThunk(params.booking));
    dispatch(payInvoiceInit());
  }, [params.booking, dispatch]);

  const status = useSelector((state) => state.consultation.data.state);
  const payStatus = useSelector((state) => state.consultation.payInvoiceStatus);
  const booking = useSelector((state) => state.consultation.data);
  const space = useSelector((state) => state.consultation.data.listing);
  const start = useSelector((state) => state.consultation.data.startDate);
  const end = useSelector((state) => state.consultation.data.endDate);
  const user = useSelector((state) => state.user.data);

  const handlePayment = ({ url }) => {
    dispatch(payInvoiceThunk({ invoiceId: params.invoiceId, url }));
  };

  const handleFinish = () => {
    navigate(`/tenant/${getConsultationStatusFromStatus(status)}/chat/${params.booking}`);
  };

  const handleOnError = () => {
    dispatch(payInvoiceInit());
  };

  return (
    <PayConsultation
      booking={booking}
      space={space}
      startDate={start}
      endDate={end}
      user={user}
      payStatus={payStatus}
      status={status}
      bookingId={params.booking}
      invoices={invoices}
      invoiceId={params.invoiceId}
      onPay={handlePayment}
      onFinish={handleFinish}
      onError={handleOnError}
    />
  );
};

export default PayConsultationContainer;
